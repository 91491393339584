export default [
    { value: null, text: 'Todos' },
    { value: 'Confirmado', text: 'Confirmado' },
    { value: 'Não Autorizado', text: 'Não Autorizado' },
    { value: 'Não Identificado', text: 'Não Identificado' },
    { value: 'Liquidado', text: 'Liquidado' },
    { value: 'Cancelado', text: 'Cancelado' },
    { value: 'Em Progresso', text: 'Em Progresso' },
    { value: 'Negado', text: 'Negado' },
    { value: 'Expirado', text: 'Expirado' },
    { value: 'Erro Confirmação', text: 'Erro Confirmação' }
]